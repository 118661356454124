<template>
  <div>
    <page-banner>
      <template #banner-txt>
        康齡頻道
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>

   <div class="common-bg padding-50">
      <div class="container">
        <div class="channel-intro">
          <h1>{{ intro.title }}</h1>
          <div class="intro-sm" v-html="intro.description"></div>
         </div>

        <div class="home-video-list">
          <ul>
            <li v-for="item in video" :key="item">
              <iframe v-bind:src="item.video_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              <p>{{item.name}}</p>
            </li>

          </ul>
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import pageBanner from "@/components/PageBanner";
 import {getChannel} from "@/network/home";

export default {
  name: "Channel",
  components: {
    pageBanner,
  },
  data() {
    return {
      banner:{},
      video: [],
      intro:{},
    }
  },
  created() {
    this.getChannel()
  },
  methods: {
    getChannel() {
      getChannel().then(res => {
        console.log(res)
        this.banner = res.data.data.banner;
        this.video = res.data.data.videos;
        this.intro =res.data.data.about;
       })
      }
  }


}
</script>

<style scoped>
.channel-intro h1{
  font-size: 40px;
  font-weight: bold;
}

.intro-sm{
  font-size: 20px; padding-top: 10px;
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .channel-intro h1{
    font-size: 24px;
   }

  .intro-sm{
    font-size: 16px;
  }
}


</style>